import React, { useContext, useState } from "react";
import LogoDark from "../../images/logo/logo.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";

import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Form, FormGroup, Spinner, Alert, Modal, ModalBody } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { loginUser } from "../../app/api";
import { useEffect } from "react";
// import AppContext from "../../context/AppContext";

const Login = () => {
  // const apc = useContext(AppContext);
  const auth = localStorage.getItem("accessToken");
  if(auth) window.location.href =  `${process.env.PUBLIC_URL}/`;
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");
  const [becomePublisher, setBecomePublisher] = useState(false);
  const [email, setEmail] = useState("");

  const getBrowserName = () => {
    var nAgt = navigator.userAgent;
    let browserName = "";

    if (nAgt.indexOf("Opera") != -1) {
      browserName = "Opera";
    } else if (nAgt.indexOf("MSIE") != -1) {
      browserName = "Microsoft Internet Explorer";
    } else if (nAgt.indexOf("Edg") != -1) {
      browserName = "Microsoft Edge";
    } else if (nAgt.indexOf("Chrome") != -1) {
      browserName = "Chrome";
    } else if (nAgt.indexOf("Safari") != -1) {
      browserName = "Safari";
    } else if (nAgt.indexOf("Firefox") != -1) {
      browserName = "Firefox";
    } else {
      browserName = "--";
    }

    return browserName;
  };

  const onFormSubmit = async (formData) => {
    setLoading(true);
    let browser = getBrowserName();
    let uname = window.btoa(formData.name);
    let upass = window.btoa(formData.passcode);
    const res = await loginUser(uname, upass, browser);

    if (res.token) {
      localStorage.setItem("accessToken", res.token);
      localStorage.setItem("uid", res.uid);
      localStorage.setItem("fname", res.fname);
      localStorage.setItem("lname", res.lname);
      localStorage.setItem("email", res.email);
      localStorage.setItem("wlt", res.wallet);
      localStorage.setItem("earn", res.total_earning);
      localStorage.setItem("wid", res.total_withdrawl);
      localStorage.setItem("utype", res.account_type);
      localStorage.setItem("urole", res.urole);
      localStorage.setItem("authorization", res.authorization);
      localStorage.setItem("firstlogin", 1);
      localStorage.removeItem("appBannerDismiss");
      localStorage.setItem("perms", '9999');
      localStorage.setItem("support_pin", res.support_pin);
      localStorage.setItem("mail_verified", res.mail_verified);
      // apc.setState({
      //   username: res.fname+' '+res.lname
      // });
      setTimeout(() => {
        window.history.pushState(
          `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
          "auth-login",
          `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
        );
        window.location.reload();
      }, 2000);
    } else {
      setTimeout(() => {
        setError(res.message);
        if(res.code  == 406)
        setBecomePublisher(true)
        setLoading(false);
      }, 2000);
    }
    setLoading(false);
  };

  const { errors, register, handleSubmit } = useForm();

  const [isMobile, setIsMobile] = useState(false); 
  const [appModal, setAppModal] = useState(false);  
  
  const openAppOrStore = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.includes("android");
    const isIOS = userAgent.includes("iphone") || userAgent.includes("ipad");

    const androidScheme = "intent://sevensearch#Intent;scheme=sevensearchpublisher;package=pub7.searchppc.com;end;";
    const iosScheme = "sevensearchpublisher://";

    if (isAndroid) {
        window.location = androidScheme;
        setTimeout(() => {
            window.open("https://play.google.com/store/apps/details?id=pub7.searchppc.com", "_blank");
        }, 2000);
    } else if (isIOS) {
        window.location = iosScheme;
        setTimeout(() => {
            window.open("https://apps.apple.com/in/app/7search-ppc-publisher/id6698887640", "_blank");
        }, 2000);
    } else {
        window.open("https://publisher.7searchppc.com", "_blank");
    }
  }; 

  useEffect(() => {
    const checkMobileView = () => {
      setIsMobile(window.innerWidth <= 768);
    };
  
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
  
    return () => window.removeEventListener("resize", checkMobileView);
  }, []);
  
  useEffect(() => {
    if (isMobile) {
      setAppModal(true);
    }
  }, [isMobile]);

  return (
    <React.Fragment>
      <Head title="Login" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <a href="https://www.7searchppc.com" target={"_blank"} className="logo-link">
              <img className="logo-dark" src={LogoDark} alt="logo-dark" />
            </a>
          </div>

          <PreviewCard className="card-bordered lgbox" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h3">Log in as a <span className="text-primary">Publisher</span></BlockTitle>
                {/* <BlockDes>
                  <p>Login account using your email and password.</p>
                </BlockDes> */}
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger">
                  <p><Icon name="alert-circle" /> {errorVal} 
                    {
                      (becomePublisher) && 
                      <a
                        href={`https://advertiser.7searchppc.com/become-publisher/${email}`}
                        className={`btn btn-primary btn-sm mt-1`}
                      >
                      Become Publisher
                      </a>
                    }
                  </p>
                </Alert>
              </div>
            )}
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email Address
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="email"
                    id="default-01"
                    name="name"
                    onChange={(e)=>setEmail(window.btoa(e.target.value))}
                    ref={register({ required: "This field is required." })}
                    defaultValue=""
                    placeholder="Enter your email address"
                    className="form-control-lg form-control"
                  />
                </div>
                  {errors.name && <span className="invalid" style={{color: '#bf4343'}}>{errors.name.message}</span>}
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    name="passcode"
                    defaultValue=""
                    ref={register({ required: "This field is required." })}
                    placeholder="Enter your password"
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                  />
                </div>
                  {errors.passcode && <span className="invalid" style={{color: '#bf4343'}}>{errors.passcode.message}</span>}
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    
                  </label>
                  <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/forget-password`}>
                    Forgot Password?
                  </Link>
                </div>
                
              </FormGroup>
              <FormGroup>
                <Button size="lg" className="btn-block" type="submit" color="primary">
                  {loading ? <Spinner size="sm" color="light" /> : "Login"}
                </Button>
              </FormGroup>
            </Form>
            <div className="form-note-s2 text-center pt-4">
              {" "}
              New on our platform?{" "}
              <a href="https://www.7searchppc.com/pub-register" target={"_blank"}>
                Create Publisher Account
              </a>
            </div>
            <div className="text-center mt-2">
            <a href="https://advertiser.7searchppc.com/auth-login" target={"_blank"} className="mt-2 btn btn-primary">Advertiser Login</a>
            </div>
          </PreviewCard>
        </Block>
        <footer>
          <div className="container-fluid">
            <div className="row p-3">
           
              <div className="col-md-6 mb-2">
                <ul className="d-flex justify-content-center">
                  <li className="footer-item mr-2 mr-md-4">
                    <a href="https://www.7searchppc.com/faqs" target="_blank">FAQ</a>
                  </li>
                  <li className="footer-item mr-2 mr-md-4">
                    <a href="https://www.7searchppc.com/about" target="_blank">About</a>
                  </li>
                  <li className="footer-item mr-2 mr-md-4">
                    <a href="https://www.7searchppc.com/privacy-policy" target="_blank">Privacy Policy</a>
                  </li>
                  <li className="footer-item mr-2 mr-md-4">
                    <a href="https://www.7searchppc.com/terms-conditions" target="_blank">Terms & Conditions</a>
                  </li>
                  <li className="footer-item mr-2 mr-md-4">
                    <a href="https://www.7searchppc.com/blog" target="_blank">Blog</a>
                  </li>
                  <li className="footer-item mr-2 mr-md-4">
                    <a href="https://www.7searchppc.com/contact" target="_blank">Contact us</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 text-center text-md-right mb-2">
                <div className="social-footer">
                  <a target="_blank" href="https://www.facebook.com/7searchPPCads"><i className="icon ni ni-facebook-fill" style={{ fontSize: "26px" }}></i></a>
                  {/* <a target="_blank" href="https://twitter.com/7searchppc"><i className="icon ni ni-twitter-round" style={{ fontSize: "26px" }}></i></a> */}
                  <a target="_blank" href="https://x.com/7searchppc_ads"><i className="icon ni ni-twitter-round" style={{ fontSize: "26px" }}></i></a> 
                  <a target="_blank" href="https://www.instagram.com/7searchppc_ads/"><i className="icon ni ni-instagram-round" style={{ fontSize: "26px" }}></i></a>
                  <a target="_blank" href="https://www.linkedin.com/company/7searchppc/"><i className="icon ni ni-linkedin-round" style={{ fontSize: "26px" }}></i></a>
                  <a target="_blank" href="https://www.pinterest.com/7search_ppc_ads/"><i className="icon ni ni-pinterest-round" style={{ fontSize: "26px" }}></i></a>
                  <a target="_blank" href="https://www.youtube.com/@7search_official"><i className="icon ni ni-youtube-round" style={{ fontSize: "26px" }}></i></a>
                  <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </div>
              <div className="nk-footer-copyright-login col-md-3 mb-2 order-md-first">
                <p>&copy; {new Date().getFullYear()} 7Search PPC<sup style={{verticalAlign:'super'}}>&reg;</sup> - All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </footer>
      </PageContainer>
      {isMobile && appModal && (
        <Modal
          isOpen={appModal}
          toggle={() => {
            setAppModal(false);
          }}
          className="modal-dialog-bottom animated-modal"
          size="md"
          modalClassName="custom-modal"
        >
          <div className="modal-header" style={{ display: "block" }}>
            <h5 className="modal-title" style={{ textAlign: "center" }}>
              Try Our Mobile App
            </h5>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                setAppModal(false);
              }}
              className="close"
              style={{ margin: "-2.7rem -1.60rem -2rem auto" }}
            >
              <Icon name="cross-sm"></Icon>
            </a>
          </div>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12 text-center">
                <h4 className="mt-2 mb-2 hdcolor">Switch to 7Search PPC App</h4>
                <p>For a Faster, Smoother, and More Convenient Experience!</p>
                <Button
                  type="button"
                  color="primary"
                  size="lg"
                  onClick={openAppOrStore}
                  className="mt-1 mb-3"
                >
                  Open with App
                </Button>
              </div>
              <div className="col-lg-12 text-center">
                <a
                  href="#"
                  color="secondary"
                  size="lg"
                  onClick={() => {
                    setAppModal(false);
                  }}
                  className="mt-3 mb-2"
                  style={{ textDecoration: "underline", cursor: "pointer", color: "#007bff", textUnderlineOffset: "2px", textDecorationThickness: "1px", }}
                >
                  Continue with Web
                </a>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </React.Fragment>
  );
};
export default Login;
